<template>
  <a-drawer
    v-model:visible="visible"
    :maskClosable="false"
    :keyboard="false"
    width="100%"
    :title="$t('TrainMaterialFilling')"
  >
    <div class="detail">
      <div class="title-wrap">{{ $t("lecturer.basic_info") }}</div>
      <a-form
        class="edit-form-wrap"
        layout="vertical"
        ref="formRef"
        :model="formData"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item
              :label="$t('MaterialFilingName')"
              name="materialName"
            >
              <a-input
                :maxlength="50"
                v-model:value="formData.materialName"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('LB_Course_Cate')"
              name="folderName"
            >
              <a-input
                :maxlength="50"
                v-model:value="formData.folderName"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('TransmissionUnit')"
              name="materialAgencyName"
            >
              <a-input
                :maxlength="50"
                v-model:value="formData.materialAgencyName"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="title-wrap">{{ $t("TransmissionOfMaterial") }}</div>
      <a-row :gutter="[24, 24]">
        <a-col :span="12" v-for="(value, key) in state.fileObj" :key="key">
          <div class="file">
            <div class="file-type">
              {{ value.name }}
              <a-tooltip :title="value.tip">
                <ExclamationCircleOutlined v-if="value.tip" />
              </a-tooltip>
            </div>
            <div class="file-list">
              <div class="file-item" v-for="(file, index) in value.list" :key="index">
                <div class="name">{{ file.name }}</div>
                <div class="op">
                  <template v-if="disabled">
                    <div class="view" v-if="!state.noViewSuffix.includes(file.suffix)" @click="preview(file)">
                      <EyeOutlined />
                    </div>
                    <div class="down" @click="fileDownload(file.sourceFilePath || file.filePath, file.name)">
                      <VerticalAlignBottomOutlined />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="footer">
      <!-- 取消 -->
      <a-button @click="visible = false">{{ $t("CM_Cancel") }}</a-button>
    </div>
  </a-drawer>
  <docPreview
    :isVisible="stateView.previewVisible"
    :fileStatus="stateView.fileStatus"
    :fileType="stateView.fileType"
    :filePath="stateView.filePath"
    :fileName="stateView.fileName"
    :fileHome="stateView.fileHome"
    @previewEnd="
      e => {
        stateView.previewVisible = e;
      }
    "
  />
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive } from "vue";
import { fileDownload } from '@/utils/tools';
import docPreview from '@/components/preview/index';

const { t: $t } = useI18n();
const visible = ref(false);

const formData = ref({
  planId: 0,
  secretData: 0,
  planName: "",
  demandCollectionId: 0,
  demandCollectionName: "",
  demandIds: [],
  hostDepartmentId: "",
  implementDepartmentId: "",
  trainingTime: [],
  budgetAmount: 0,
});
const disabled = ref(true);
const state = reactive({
  fileObj: {
    train: {
      name: $t('TrainingNotice'),
      type: 1,
      list: [],
    },
    roster: {
      name: $t('Roster'),
      type: 2,
      list: [],
    },
    summarize: {
      name: $t('Summarize'),
      type: 3,
      list: [],
    },
    schedule: {
      name: $t('CourseSchedule'),
      type: 4,
      list: [],
    },
    courseware: {
      name: $t('Courseware'),
      type: 5,
      list: [],
      tip: $t('CoursewareTip'),
    },
  },
  fileTypeMap: {
    1: 'train',
    2: 'roster',
    3: 'summarize',
    4: 'schedule',
    5: 'courseware',
  },
  noViewSuffix: '.ppt,.pptx,.doc,.docx,.xls,.xlsx,.zip',
});

function show(data) {
  visible.value = true;
  const { materialFiles, ...res } = data;
  formData.value = res;
  materialFiles && materialFiles.forEach(item => {
    const key = state.fileTypeMap[item.materialType];
    if (item.attachments) {
      state.fileObj[key].list = item.attachments.map(item => {
        item.name = item.fileName;
        item.file = item.md5;
        return item;
      });
    }
  });
}
const stateView = reactive({
  previewVisible: false,
  fileStatus: 1,
  fileType: '',
  filePath: '',
  fileName: '',
  fileHome: '',
});
function preview(data) {
  stateView.previewVisible = true;
  stateView.fileStatus = data.status;
  stateView.fileType = data.fileType;
  stateView.filePath = data.filePath;
  stateView.fileName = data.fileName || '';
  stateView.fileHome = data.home || '';
};

defineExpose({
  show,
});
</script>
<style lang="less" scoped>
.detail {
  width: 950px;
  margin: 0 auto;
  padding-bottom: 80px;
  .title-wrap {
    position: relative;
    padding: 10px;
    font-size: 16px;
    &::before {
      content: "";
      position: absolute;
      top: 16px;
      left: -2px;
      height: 16px;
      width: 4px;
      background-color: var(--theme);
    }
  }
  .file {
    &-type {
      margin-bottom: 10px;
    }
    &-list {
      .file-item {
        display: flex;
        align-items: center;
        padding: 4px 5px;
        background-color: #f5f5f5;
        margin-top: 5px;
        .name {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .op {
          display: flex;
          cursor: pointer;
          div {
            padding: 0 5px;
            color: var(--theme);
          }
          .del {
            color: red;
          }
        }
      }
    }
  }
}
.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  padding: 10px 0;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #ccc;
}
.pr6 {
  padding-right: 6px;
}
.w160 {
  width: 160px;
}
</style>
