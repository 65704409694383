<template>
  <div class="wrap">
    <div class="title">
      {{ dataInfo.resourceName }}
      <span class="status" :class="`audit-status-${dataInfo.auditStatus}`">
        {{ auditStatusMap[dataInfo.auditStatus] }}
      </span>
    </div>
    <div class="user">
      <a-avatar class="avatar" :size="30" :src="dataInfo.initMemberPortrait">
        <template #icon><UserOutlined /></template>
      </a-avatar>
      <div class="name">
        {{ dataInfo.initMemberName }}
      </div>
      <div class="depart">
        {{ dataInfo.initMemberDepartmentName }}
      </div>
    </div>
  </div>
  <div class="wrap">
    <!-- 名称 -->
    <div class="task-name">
      {{ $t("CM_LB_Name") }}: {{ dataInfo.resourceName }}
    </div>
    <div class="task-name" v-if="dataInfo.limitStartTime || dataInfo.limitEndTime">
      {{ $t('subject.learning_time') }}:
      <span v-if="dataInfo.limitStartTime">
        {{ dateFormat(dataInfo.limitStartTime, 'YYYY-MM-DD') }} 00:00
      </span>
      <span v-else>{{ $t('Unrestricted') }}</span>
      <span v-if="dataInfo.limitEndTime">
        ~
        {{ dateFormat(dataInfo.limitEndTime, 'YYYY-MM-DD') }} 23:59
      </span>
      <span v-else>{{ $t('Unrestricted') }}</span>
      <template v-if="dataInfo.studyCondition">
        (
        <span v-if="[1, 3].includes(dataInfo.studyCondition)">
          {{ $t('YouCanLearnBeforeYouStart') }}
        </span> 
        <span v-if="dataInfo.studyCondition === 3">、</span>
        <span v-if="[2, 3].includes(dataInfo.studyCondition)">
          {{ $t('YouCanLearnAtTheEnd') }}
        </span> 
        )
      </template>
    </div>
    <div class="action">
      <!-- 查看详情 -->
      <a-button type="link" @click="$refs.detailRef.show(dataInfo)">{{
        $t("view_detail")
      }}</a-button>
      <!-- 设置课程学习时间范围 -->
      <a-button type="link" @click="openPeriod(false)">{{
        $t("SetStudyPeriods")
      }}</a-button>
    </div>
  </div>
  <!-- 详情 -->
  <detail ref="detailRef" />
  <!-- 设置学习期限 -->
  <setPeriod
    ref="setPeriodRef"
    :realName="dataInfo.initMemberName"
    :setting="setting"
    @on-success="handleSuccess"
  />
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { auditStatusMap } from "@/utils/business.js";
import { dateFormat } from "@/utils/tools.js";
import detail from "./detail.vue";
import setPeriod from "./setPeriod.vue";

const { t: $t } = useI18n();
const emit = defineEmits(["emit"]);
const props = defineProps({
  dataInfo: {
    type: Object,
    default: () => {},
  },
  setting: {
    type: Object,
    default: () => {},
  },
});


const setPeriodRef = ref(null);
function openPeriod(submit = false, data = {}) {
  setPeriodRef.value.openPeriod(submit, data);
};

function handleSuccess() {
  emit('on-success');
}
defineExpose({
  openPeriod,
});
</script>
<style lang="less" scoped>
.mr15 {
  margin-right: 15px;
}
.mb10 {
  margin-bottom: 10px;
}
.w-full {
  width: 100%;
}
</style>
