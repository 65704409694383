<template>
  <a-modal
    v-model:visible="visible"
    :maskClosable="false"
    :keyboard="false"
    width="700px"
    :title="$t('bbs.addpost.choose_person')"
    @ok="handleOk"
    @cancel="hide"
  >
    <div class="search">
      <a-input
        v-model:value.trim="queryParam.keyword"
        :placeholder="$t('teaching.please_enter_name_account')"
        allow-clear
        @change="handleSearch"
      />
    </div>
    <a-table
      size="small"
      :scroll="{ x: 'max-content', y: 360 }"
      :columns="columns"
      :row-selection="{
        selectedRowKeys: stateData.selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="userId"
      :data-source="state.dataSource"
      :bordered="false"
      :pagination="pagination"
      :loading="state.loading"
      @change="handleTableChange"
    >
      <template #realName="{ text }">
        <OpenData
          type="userName"
          :openid="text"
        />
      </template>
      <template #member="{ text }">
        <a-tooltip placement="topLeft">
          <template #title>
            <template v-for="(item, index) in text" :key="index">
              <OpenData
                class="pr6"
                type="userName"
                :openid="item.lecturerName || item.realName"
                v-if="item.userId"
              />
              <span class="pr6" v-else>{{ item.lecturerName || item.realName }}</span>
            </template>
          </template>
          <div class="w160 ellipsis">
            <template v-for="(item, index) in text" :key="index">
              <OpenData
                class="pr6"
                type="userName"
                :openid="item.lecturerName || item.realName"
                v-if="item.userId"
              />
              <span class="pr6" v-else>{{ item.lecturerName || item.realName }}</span>
            </template>
          </div>
        </a-tooltip>
      </template>
    </a-table>
  </a-modal>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance, toRefs, toRaw } from "vue";
import { getPlanManager } from "@/api/auditCenter";
import { useFetchData } from "@/utils/useFetchData";
import OpenData from "@/components/OpenData.vue";

const emit = defineEmits(["emit"]);
const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const visible = ref(false);
const columns = ref([
  {
    // title: "姓名",
    title: $t("Pub_Lab_UserName"),
    dataIndex: "realName",
    width: 150,
    ellipsis: true,
    slots: { customRender: "realName" },
  },
  {
    // title: "账号",
    title: $t("login.account"),
    dataIndex: "account",
    width: 150,
    ellipsis: true,
  },
  {
    // title: "部门",
    title: $t("CM_Department"),
    dataIndex: "departmentName",
    width: 150,
    ellipsis: true,
    slots: { customRender: "department" },
  },
]);
const queryParam = ref({
  page: 1,
  pageSize: 30,
  keyword: '',
});

function show() {
  visible.value = true;
  stateData.selectedRowKeys = [];
}
function hide() {
  visible.value = false;
}


function getListData(params) {
  let form = JSON.parse(JSON.stringify(queryParam.value));
  form.page = params.current;
  form.pageSize = params.pageSize;
  return getPlanManager(form).then((res) => {
    return {
      success: true,
      data: res.data.list,
      current: res.data.page,
      pageSize: res.data.pageSize,
      total: res.data.totals,
    };
  });
}
const {
  stripe,
  reload,
  setPageInfo,
  context: state,
} = useFetchData(getListData, {
  current: 1,
  pageSize: 30,
  tableSize: "middle", // 'default' | 'middle' | 'small'
  stripe: false,
});
function onSearch() {
  getListData();
}
const handleTableChange = ({ current, pageSize }, filters, sorter) => {
  setPageInfo({
    current,
    pageSize,
    sortKey: sorter.field,
    sort: sorter.order,
    ...filters,
  });

  reload();
};
const { total, pageSize, current } = toRefs(state);
const pagination = reactive({
  showSizeChanger: true,
  showQuickJumper: false,
  pageSizeOptions: ["10", "30", "50", "100"],
  current,
  pageSize,
  total,
  onChange: (page, pageSize) => {
    setPageInfo({ current: page, pageSize });
    reload();
  },
  onShowSizeChange: (current, size) => {
    setPageInfo({ current, pageSize: size });
    reload();
  },
});
const handleSearch = () => {
  setPageInfo({ current: 1, ...toRaw(queryParam) });
  reload();
};

const stateData = reactive({
  selectedRowKeys: [],
});
function onSelectChange(selectedRowKeys) {
  stateData.selectedRowKeys = selectedRowKeys
}

function handleOk() {
  if (stateData.selectedRowKeys.length) {
    emit('on-select', stateData.selectedRowKeys);
  }
  hide();
};

defineExpose({
  show,
});
</script>
<style lang="less" scoped>
.search {
  width: 200px;
  margin-bottom: 10px;
}
.pr6 {
  padding-right: 6px;
}
.w160 {
  width: 160px;
}
</style>
