<template>
  <a-modal
    v-model:visible="visible"
    :maskClosable="false"
    :keyboard="false"
    :closable="false"
    width="500px"
    @ok="handleOk"
    @cancel="hide"
  >
    <a-form ref="formRef" :model="formState" layout="vertical">
      <!-- 通过 -->
      <a-form-item
        v-if="formState.auditStatus === 2"
        :label="$t('ConfirmType', { type: $t('teacher.review_pass') })"
        name="auditDesc"
        :rules="[
          {
            required: agreeRequired,
            message: $t('PleaseEnterAReviewComment', { type: agreeRequired ? $t('Required') : $t('Optional') }),
          },
        ]"
      >
        <a-textarea
          v-model:value="formState.auditDesc"
          :maxlength="500"
          :rows="4"
          allowClear
          showCount
          :placeholder="$t('PleaseEnterAReviewComment', { type: agreeRequired ? $t('Required') : $t('Optional') })"
        />
      </a-form-item>
      <!-- 驳回 -->
      <a-form-item
        v-if="formState.auditStatus === 3"
        :label="$t('ConfirmType', { type: $t('teacher.review_rejected') })"
        name="auditDesc"
        :rules="[
          {
            required: refuseRequired,
            message: $t('PleaseEnterAReviewComment', { type: refuseRequired ? $t('Required') : $t('Optional') }),
          },
        ]"
      >
        <a-textarea
          v-model:value="formState.auditDesc"
          :maxlength="500"
          :rows="4"
          allowClear
          showCount
          :placeholder="$t('PleaseEnterAReviewComment', { type: refuseRequired ? $t('Required') : $t('Optional') })"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance } from "vue";
import { getAuditNodeDetail, submitAudit } from "@/api/auditCenter";

const emit = defineEmits(["emit"]);
const props = defineProps({
  setting: {
    type: Object,
    default: () => {},
  },
  auditType: {
    type: Number,
    default: 0,
  },
});
const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const visible = ref(false);
const formRef = ref(null);
const agreeRequired = ref(false);
const refuseRequired = ref(false);
const formState = reactive({
  auditDesc: '',
  auditStatus: 0,
  receiptIds: 0,
});
const rules = {
  auditDesc: [
    {
      required: true,
      message: $t("PleaseEnterAReviewComment"),
      trigger: "change",
    },
  ],
};

let hasAuditTypeCourse = false;
async function show(val, list) {
  visible.value = true;
  formState.auditDesc = '';
  formState.auditStatus = val;
  if (list) {
    const ids = [];
    list.forEach(item => {
      ids.push(item.receiptId);
      if (item.auditType == 5) hasAuditTypeCourse = true;
    });
    formState.receiptIds = ids;
    // 获取配置项
    const res = await getAuditNodeDetail(ids[0]);
    if (res.ret === 0) {
      agreeRequired.value = res.data.agree === 2;
      refuseRequired.value = res.data.refuse === 2;
    }
  } else {
    formState.receiptIds = [props.setting.receiptId];
    agreeRequired.value = props.setting.agree === 2;
    refuseRequired.value = props.setting.refuse === 2;
  }
}
function hide() {
  visible.value = false;
}

function handleOk() {
  formRef.value
    .validate()
    .then(async () => {
      // 在线课程 审批通过 和设置课程学习时间一起提交
      if ((props.auditType === 5 || hasAuditTypeCourse) && formState.auditStatus === 2) {
        hide();
        return emit('set-course-period', formState);
      }
      const res = await submitAudit(formState);
      if (res.ret === 0) {
        proxy.$message.success($t('CM_Success'));
        emit('on-success')
        hide();
      } else {
        if(props.setting.auditType!=4) {
          proxy.$message.error($t('CM_Fail'));
        }
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
}
defineExpose({
  show,
});
</script>
<style lang="less" scoped>
</style>
