<template>
  <div class="down">
    <div class="down-head" id="pageTop">
      <ArrowLeftOutlined class="back" @click="back()" />
      <!-- 审核中心 -->
      <h3>{{ $t("AuditCenter") }}</h3>
    </div>
    <div class="down-body">
      <div class="down-body-content">
        <div class="tab">
          <a-tabs v-model:activeKey="activeKey" @change="changeTab">
            <!-- 我发布的 -->
            <a-tab-pane key="1" :tab="$t('IPostedIt')"></a-tab-pane>
            <!-- 我审核的 -->
            <a-tab-pane key="2" :tab="$t('IAuditedIt')"></a-tab-pane>
          </a-tabs>
        </div>
        <div class="search">
          <div
            class="search-item batch"
            v-show="
              activeKey == 2 && queryParams.myauditStatus == 1 && pageTotal
            "
            @click="showChecked = true"
          >
            <!-- 批量操作 -->
            <UnorderedListOutlined />
            {{ $t("train.batch_operation") }}
          </div>
          <div class="search-item border">
            <div class="search-icon" @click="search">
              <SearchOutlined style="color: #656565" />
            </div>
            <a-input
              class="search-input"
              v-model:value.trim="queryParams.keyword"
              :placeholder="$t('LB_Coach_EnterName')"
              allow-clear
              @pressEnter="onSearch"
              @change="onSearch"
            />
          </div>
          <div class="search-item">
            <a-select
              v-if="activeKey == 1"
              class="w-100"
              v-model:value="queryParams.auditStatus"
              :placeholder="$t('CM_Select') + $t('CM_Status')"
              @change="onSearch"
            >
              <a-select-option :value="0">{{ $t("CM_All") }}</a-select-option>
              <a-select-option :value="1">{{
                $t("teacher.under_review")
              }}</a-select-option>
              <a-select-option :value="2">{{
                $t("Pub_Passed")
              }}</a-select-option>
              <a-select-option :value="3">{{
                $t("teacher.rejected")
              }}</a-select-option>
              <a-select-option :value="4">{{
                $t("Withdrawn")
              }}</a-select-option>
            </a-select>
            <a-select
              v-else
              class="w-100"
              v-model:value="queryParams.myauditStatus"
              :placeholder="$t('CM_Select') + $t('CM_Status')"
              @change="onSearch"
            >
              <a-select-option :value="0">{{ $t("CM_All") }}</a-select-option>
              <a-select-option :value="1">{{
                $t("PendingMyReview")
              }}</a-select-option>
              <a-select-option :value="2">{{
                $t("IHavePassed")
              }}</a-select-option>
              <a-select-option :value="3">{{
                $t("IveDismissedIt")
              }}</a-select-option>
            </a-select>
          </div>
          <div class="search-item">
            <a-select
              class="w-100"
              v-model:value="queryParams.auditType"
              :placeholder="$t('CM_Select') + $t('XB_Type')"
              @change="onSearch"
            >
              <a-select-option :value="0">{{ $t("CM_All") }}</a-select-option>
              <a-select-option
                v-for="item in auditTypeOption"
                :value="item.id"
                :key="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </div>
          <div class="search-item">
            <a-range-picker
              v-model:value="queryParams.initTime"
              :show-time="{ format: 'HH:mm' }"
              valueFormat="YYYY-MM-DD HH:mm"
              format="YYYY-MM-DD HH:mm"
              @change="onSearch"
            />
          </div>
        </div>
        <a-spin :spinning="loading">
          <template v-if="dataList.length">
            <a-row :gutter="16">
              <a-col :span="6" v-for="item in dataList" :key="item.downId">
                <a-card hoverable class="down-item" @click="handleDetail(item)">
                  <template v-if="item.auditType == 2">
                    <div class="title">
                      <a-checkbox
                        v-show="showChecked"
                        v-model:checked="item.checked"
                        @click.stop=""
                      ></a-checkbox>
                      {{ $t("NameAccount") }}：{{ item.resourceName }}
                    </div>
                    <div class="publish">
                      <div class="name">
                        <a-tooltip :title="item.department">
                          {{ item.departmentName }}
                        </a-tooltip>
                      </div>
                      <div class="date">{{ dateFormat(item.initTime) }}</div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="title">
                      <a-checkbox
                        v-show="showChecked"
                        v-model:checked="item.checked"
                        @click.stop=""
                      ></a-checkbox>
                      <template v-if="item.auditType === 3">
                        {{ $t("MaterialFilingName") }}
                      </template>
                      <template v-if="item.auditType === 4">
                        {{ $t("TrainingRoom") }}：
                      </template>
                      <template v-else>
                        {{ auditTypeMap[item.auditType] }}：
                      </template>
                      {{ item.resourceName }}
                    </div>
                    <div class="publish">
                      <div class="name">
                        <a-avatar
                          class="avatar"
                          :size="30"
                          :src="item.initMemberPortrait"
                        >
                          <template #icon><UserOutlined /></template>
                        </a-avatar>
                        <OpenData
                          type="userName"
                          :openid="item.initMemberName"
                        />
                      </div>
                      <div class="date">{{ dateFormat(item.initTime) }}</div>
                    </div>
                  </template>
                  <div class="type">{{ auditTypeMap[item.auditType] }}</div>
                  <div
                    class="status"
                    :class="`audit-status-${item.auditStatus}`"
                  >
                    {{ auditStatusMap[item.auditStatus] }}
                  </div>
                </a-card>
              </a-col>
            </a-row>
            <div class="page-Wrap">
              <a-pagination
                show-quick-jumper
                :defaultPageSize="20"
                v-model:current="currentPage"
                :show-total="(total) => $t('InTotal', { total: pageTotal })"
                :total="pageTotal"
                @change="pageChange"
              >
                <template #itemRender="{ type, originalElement }">
                  <a-button v-if="type === 'prev'">{{
                    $t("LastPage")
                  }}</a-button>
                  <a-button v-else-if="type === 'next'">{{
                    $t("NextPage")
                  }}</a-button>
                  <renderVNode v-else :vnode="originalElement"></renderVNode>
                </template>
              </a-pagination>
            </div>
          </template>
          <a-empty v-else style="padding-top: 100px" />
        </a-spin>
      </div>
      <div class="footer" v-if="showChecked && pageTotal">
        <div class="checked-all">
          <a-checkbox
            v-model:checked="checkAll"
            :indeterminate="indeterminate"
            @change="onCheckAllChange"
          >
            {{ selectRows.length }}/{{ pageTotal }}
          </a-checkbox>
        </div>
        <div class="btn">
          <a-space>
            <!-- 通过 -->
            <a-button
              :disabled="!selectRows.length"
              type="primary"
              @click="$refs.auditOpinionModalRef.show(2, selectRows)"
              >{{ $t("CM_Pass") }}</a-button
            >
            <!-- 驳回 -->
            <a-button
              :disabled="!selectRows.length"
              @click="$refs.auditOpinionModalRef.show(3, selectRows)"
              >{{ $t("TurnDown") }}</a-button
            >
            <!-- 取消 -->
            <a-button @click="cancelSelectAll">{{ $t("CM_Cancel") }}</a-button>
          </a-space>
        </div>
      </div>
    </div>
    <detailDrawer ref="detailDrawerRef" @complete="changeTab" />
    <auditOpinionModal
      ref="auditOpinionModalRef"
      @on-success="changeTab"
      @set-course-period="(data) => $refs.setPeriodRef.openPeriod(true, data)"
    />
    <setPeriod ref="setPeriodRef" @on-success="changeTab" />
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

import { dateFormat } from "@/utils/tools.js";
import { auditTypeMap, auditStatusMap } from "@/utils/business.js";

const renderVNode = (_, { attrs: { vnode } }) => vnode;

import detailDrawer from "./detailDrawer";
import OpenData from "@/components/OpenData.vue";
import { getPublishList, getAuditList } from "@/api/auditCenter";
import { companyInfo } from "@/api/user";
import auditOpinionModal from "./auditOpinionModal";
import setPeriod from "./course/setPeriod.vue";

export default defineComponent({
  components: {
    renderVNode,
    detailDrawer,
    OpenData,
    auditOpinionModal,
    setPeriod,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const { t: $t } = useI18n();
    const state = reactive({
      loading: false,
      dataList: [],
      currentPage: 1,
      pageTotal: 0,
      queryParams: {
        keyword: "",
        auditStatus: 1,
        myauditStatus: 1,
        auditType: Number(route.query.auditType || 0),
        initTime: [],
      },
      activeKey: "2",
      showChecked: false,
      checkAll: false,
      indeterminate: true,
      selectRows: [],
      auditTypeOption: [
        {
          id: 2,
          name: $t("CompletePersonnelInformation"),
        },
      ],
      ddtab: route.query.ddtab,
    });

    if (route.query.myauditStatus == "-1") {
      state.queryParams.myauditStatus = 0;
    }

    const back = () => {
      if (state.ddtab) {
        window.close();
      } else {
        router.go(-1);
      }
    };
    const initOption = async () => {
      const res = await companyInfo();
      if (res.ret === 0) {
        const menu = res.data.menu;
        if (menu.includes("plan")) {
          state.auditTypeOption.push({
            id: 1,
            name: $t("TrainingPrograms"),
          });
        }
        if (menu.includes("filling")) {
          state.auditTypeOption.push({
            id: 3,
            name: $t("TrainMaterialFilling"),
          });
        }
        if (menu.includes("classroom")) {
          state.auditTypeOption.push({
            id: 4,
            name: $t("TrainingClassroomReservation"),
          });
        }
        if (menu.includes("course")) {
          state.auditTypeOption.push({
            id: 5,
            name: $t("OnlineCourseEnrollmentApplication"),
          });
        }
      }
    };
    initOption();

    const getListData = (scroll = false) => {
      state.loading = true;
      const method = state.activeKey == 1 ? getPublishList : getAuditList;
      method({
        page: state.currentPage,
        pageSize: 16,
        ...state.queryParams,
      }).then((res) => {
        state.loading = false;
        if (res.ret === 0) {
          state.pageTotal = res.data.totals;
          state.dataList = res.data.list || [];
          if (scroll) {
            setTimeout(() => {
              document.getElementById("pageTop").scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 0);
          }
        }
      });
    };
    getListData();

    const pageChange = (n) => {
      state.currentPage = n;
      getListData(true);
    };

    const changeTab = () => {
      cancelSelectAll();
      state.queryParams = {
        keyword: "",
        auditStatus: 1,
        myauditStatus: 1,
        auditType: 0,
        initTime: [],
      };
      onSearch();
    };

    const onSearch = () => {
      state.currentPage = 1;
      getListData();
    };

    const onCheckAllChange = (e) => {
      state.dataList.forEach((item) => (item.checked = e.target.checked));
      state.indeterminate = false;
    };
    const cancelSelectAll = () => {
      state.dataList.forEach((item) => (item.checked = false));
      state.showChecked = false;
    };
    watch(
      () => state.dataList,
      (val) => {
        state.selectRows = [];
        val.forEach((item) => {
          if (item.checked) state.selectRows.push(item);
        });
        state.indeterminate =
          !!state.selectRows.length && state.selectRows.length < val.length;
        state.checkAll = state.selectRows.length === val.length;
      },
      { deep: true }
    );

    const detailDrawerRef = ref(null);
    const handleDetail = (item) => {
      const isNeedAudit =
        state.activeKey == 2 && state.queryParams.myauditStatus === 1;
      detailDrawerRef.value.show(item, state.activeKey, isNeedAudit);
    };
    return {
      dateFormat,
      auditTypeMap,
      auditStatusMap,
      ...toRefs(state),
      back,
      pageChange,
      changeTab,
      onSearch,
      onCheckAllChange,
      cancelSelectAll,
      detailDrawerRef,
      handleDetail,
    };
  },
});
</script>

<style scoped lang="less">
::v-deep(.ant-card) {
  .ant-card-body {
    padding: 16px;
  }
}
.down {
  min-height: 100vh;
  background-color: #f0f2f5;
  &-head {
    .mixinFlex(flex-start; center);
    height: 60px;
    padding: 0 16px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    .back {
      font-size: 16px;
      cursor: pointer;
      color: #555;
      padding: 2px;
    }
    h3 {
      margin: 0 0 0 14px;
      padding-left: 14px;
      position: relative;
      font-size: 18px;
      font-weight: 500;
      color: #333;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 1px;
        height: 20px;
        background-color: #d9d9d9;
      }
    }
  }
  &-body {
    height: 100%;
    padding: 0 16px;
    &-content {
      padding: 16px 0 80px;
    }
  }
  .tab {
    margin-bottom: 16px;
    background-color: #fff;
    ::v-deep(.ant-tabs-bar) {
      margin-bottom: 0;
    }
  }
  &-item {
    margin-bottom: 16px;
    padding-top: 30px;
    position: relative;
    //   cursor: pointer;
    .title {
      .mixinEllipsis(24px);
      color: #333;
    }
    .publish {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0 0;
      .name {
        display: flex;
        align-items: center;
        .avatar {
          margin-right: 10px;
        }
      }
    }
    .type {
      position: absolute;
      top: 0;
      left: 0;
      padding: 3px 8px;
      background-color: var(--theme);
      color: #fff;
    }
    .status {
      position: absolute;
      top: 2px;
      right: 2px;
      padding: 2px 5px;
      // background-color: #999;
      border-radius: 4px;
      color: #fff;
    }
  }
  .page-Wrap {
    text-align: center;
    margin-top: 8px;
  }
}
.search {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.search-item {
  .mixinFlex(space-between; center);
  width: 220px;
  height: 32px;
  margin-right: 16px;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 16px;
  .search-icon {
    width: 34px;
    height: 14px;
    .mixinFlex(center; center);
    cursor: pointer;
  }
  ::v-deep(.ant-input-affix-wrapper-focused) {
    border-color: none;
    box-shadow: none;
  }
  .search-input {
    border: none;
    width: calc(100% - 34px);
    height: 100%;
    background-color: #fff;
    padding: 0 8px 0 0;
    font-size: 14px;
    ::v-deep(.ant-input) {
      background-color: #fff;
    }
  }
  .w-100 {
    width: 100%;
  }
}
.batch {
  width: auto;
  padding: 0 10px;
  border: 1px solid #ccc;
  letter-spacing: 1px;
  cursor: pointer;
  &:hover {
    color: var(--theme);
  }
}
.border {
  border: 1px solid #ccc;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-top: 1xp solid #f9f9f9;
  .btn {
    flex: 1;
    text-align: center;
  }
}
</style>
