<template>
  <!-- 设置学习期限 -->
  <a-modal
    v-model:visible="visible"
    :title="realName ? `${realName}--` : '' + `${$t('SetStudyPeriods')}`"
    :confirmLoading="loading"
    :maskClosable="false"
    @cancel="handleCancel"
    @ok="handleOk"
    width="550px"
    :bodyStyle="{ padding: '12px 24px' }"
  >
    <a-form
      :model="formData"
      ref="formRef"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-item
        :label="$t('PeriodOfStudyRise')"
        name="studyLimitStartTime"
        :rules="{
          validator: validatorStart,
          trigger: 'change',
        }"
        class="mb10"
      >
        <a-date-picker
          class="w-full"
          allowClear
          v-model:value="formData.studyLimitStartTime"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          :disabled-date="current => disabledDate(current, null, formData.studyLimitEndTime)"
          :placeholder="$t('CM_Select') + $t('PeriodOfStudyRise')"
        >
          <template #suffixIcon>
            <CalendarOutlined />
          </template>
        </a-date-picker>
      </a-form-item>
      <a-form-item
        :label="$t('PeriodOfStudyStop')"
        name="studyLimitEndTime"
        :rules="{
          validator: validatorEnd,
          trigger: 'change',
        }"
        class="mb10"
      >
        <a-date-picker
          class="w-full"
          allowClear
          v-model:value="formData.studyLimitEndTime"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          :disabled-date="current => disabledDate(current, formData.studyLimitStartTime, null)"
          :placeholder="$t('CM_Select') + $t('PeriodOfStudyStop')"
        >
          <template #suffixIcon>
            <CalendarOutlined />
          </template>
        </a-date-picker>
      </a-form-item>
      <a-form-item
        :label="$t('LearningSettings')"
        name="studyCondition"
        class="mb10"
      >
        <a-checkbox-group v-model:value="formData.studyCondition">
          <a-checkbox class="mr15" :value="1">{{ $t('LearnBeforeTheCourseStarts') }}</a-checkbox>
          <a-checkbox class="mr15" :value="2">{{ $t('LearnAfterTheCourse') }}</a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance } from "vue";
import moment from 'moment';
import { dateFormat } from "@/utils/tools.js";
import { submitAudit } from "@/api/auditCenter";

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const emit = defineEmits(["emit"]);
const props = defineProps({
  realName: {
    type: String,
    default: '',
  },
  setting: {
    type: Object,
    default: () => {},
  },
});

const visible = ref(false);
const formRef = ref(null);
const loading = ref(false);
const isSubmit = ref(false);
const isReset = ref(true);
const formData = reactive({
  studyLimitStartTime: '',
  studyLimitEndTime: '',
  studyCondition: [],
});

const auditParams = ref({});
function openPeriod(submit = false, data = {}) {
  isSubmit.value = submit;
  auditParams.value = data;
  if (!isReset.value) return visible.value = true;
  formData.studyCondition = [];
  if (props.setting?.courseAuditData) {
    const { courseAuditData } = props.setting;
    formData.studyLimitStartTime = courseAuditData.studyLimitStartTime ? dateFormat(courseAuditData.studyLimitStartTime) : '';
    formData.studyLimitEndTime = courseAuditData.studyLimitEndTime ? dateFormat(courseAuditData.studyLimitEndTime) : '';
    if (courseAuditData.studyCondition) {
      formData.studyCondition = courseAuditData.studyCondition === 3 ? [1, 2] : [courseAuditData.studyCondition];
    }
  } else {
    formData.studyLimitStartTime = '';
    formData.studyLimitEndTime = '';
  }
  isReset.value = false;
  visible.value = true;
};
function validatorStart(rules, value) {
  if (!value && formData.studyCondition.includes(1)) {
    return Promise.reject($t('CM_Select') + $t('PeriodOfStudyRise'));
  }
  return Promise.resolve();
}
function validatorEnd(rules, value) {
  if (!value && formData.studyCondition.includes(2)) {
    return Promise.reject($t('CM_Select') + $t('PeriodOfStudyStop'));
  }
  return Promise.resolve();
};
function disabledDate(current, startTime, endTime) {
  return (
    (startTime && current < moment(startTime).startOf('day')) ||
    (endTime && current > moment(endTime).endOf('day'))
  );
};
function handleCondition() {
  if (formData.studyCondition.length === 0) {
    return 0;
  }
  if (formData.studyCondition.length > 1) {
    return 3;
  }
  return formData.studyCondition[0];
};
function handleCancel() {
  visible.value = false;
  if (isSubmit.value) {
    const params = {
      courseAuditData: {
        ...formData,
        studyCondition: handleCondition(),
      },
      ...auditParams.value,
    };
    submit(params);
  }
  isSubmit.value = false;
}
async function handleOk() {
  await formRef.value.validate();
  const params = {
    courseAuditData: {
      ...formData,
      studyCondition: handleCondition(),
    },
    ...auditParams.value,
  };
  if (
    params.studyLimitStartTime &&
    params.studyLimitEndTime &&
    moment(params.studyLimitStartTime) > moment(params.studyLimitEndTime)
  ) {
    return proxy.$message.error($t('TheStartTimeCannotBeGreaterThanTheEndTime'));
  }
  if (!isSubmit.value) return handleCancel();
  submit(params)
}
async function submit(params) {
  const res = await submitAudit(params);
  isSubmit.value = false;
  if (res.ret === 0) {
    proxy.$message.success($t('CM_Success'));
    handleCancel();
    emit('on-success');
  } else {
    proxy.$message.error(res.msg);
  }
  isReset.value = true;
};
defineExpose({
  openPeriod,
});
</script>

<style>

</style>